







































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import UserInfo from '@/interfaces/userInfo';
import VButton from '@/components/VButton.vue';

@Component({
  components: {VButton, },
})
export default class Caos extends Vue {
  @Getter('userInfo', {namespace: 'account'}) private userInfo!: UserInfo;
}
